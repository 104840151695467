export enum PrivateRoutes {
  workCalendar = 'work_calendar',
  personalCalendar = 'personal_calendar',
  holidays = 'holidays',
  personalEvents = 'personal_events',
  absence = 'absence',
  groupEvents = 'group_events',
  projectsTasksGantt = 'projects_tasks_gantt',
  employeesTasksGantt = 'employees_tasks_gantt',
  employees = 'employees',
  employeesSchedules = 'employees_schedules',
  projects = 'projects',
  groups = 'groups',
  entityLog = 'entity_log',
}
